@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700');

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('./fonts/GTWalsheimProLight.eot');
  src: url('./fonts/GTWalsheimProLight.eot?#iefix') format('embedded-opentype'),
  url('./fonts/GTWalsheimProLight.woff2') format('woff2'),
  url('./fonts/GTWalsheimProLight.woff') format('woff'),
  url('./fonts/GTWalsheimProLight.svg#GTWalsheimProLight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro';
  src: url('./fonts/GTWalsheimProBold.eot');
  src: url('./fonts/GTWalsheimProBold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/GTWalsheimProBold.woff2') format('woff2'),
  url('./fonts/GTWalsheimProBold.woff') format('woff'),
  url('./fonts/GTWalsheimProBold.svg#GTWalsheimProBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

.relative{
  position: relative;
}

*{
  font-family: 'GTWalsheimPro';
}

body {
  background-color: white;
  margin: 0;
  padding-top: 64px;
  color: #2f3542;
  line-height: 20px;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  width: 100%;
  overflow-x: hidden;
}

html.modal-open,
html.modal-open body{
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.detail-ref{
  padding-bottom: 49px;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #0000ba
}

/* header */
#app-header {
  background-color: #ffffff;
  border-bottom: 1px solid #dfe4ea;
  padding: 22px 0 12px 0;
  text-align: center; 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.arrow-return-header{
  position: absolute;
  left: 20px;
  top: 26px;
}

.arrow-return-header img{
  width: 20px;
}

.btn-close-nav{
  position: absolute;
  right: 20px;
  top: 24px;
}

#nav-header{
  position: fixed;
  left: 0;
  top: 0;
  transform: translate(0, -100%);
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: #0000ba;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
}

#nav-header .close-modal{
  display: block;
  height: 35px;
  width: 35px;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  font-size: 20px;
  background-color: white;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px
}

#nav-header li a{
  color: #ffffff;
}

#nav-header.active-nav{
  top: 0;
  transform: translate(0, 0);
}

.nav-burger {
  z-index: 16;
  position: absolute;
  right: 20px;
  top: 27px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.nav-burger span {
  height: 2px;
  width: 20px;
  background-color: #0000ba;
  display: block;
  margin-bottom: 4px;
  border-radius: 100px;
}

.nav-burger span:last-child{
  margin-bottom: 0;
}

#nav-header ul{
  padding: 0 0 0 8%;
  margin: 0;
}

#nav-header ul li{
  padding: 32px 20px;
  display: block;
}

#nav-header ul li a {
  font-size: 30px;
  color: #fff;
}

.logo{
  display: inline-block;
}

.logo img {
  width: 22px;
}

/* footer */
#app-footer{
  background-color: #ffffff;
  padding: 20px 0;
  font-size: 12px;
  text-align: center;
}

/* item projects */
.item-project{
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 2em;
  text-align: left;
}

.item-project:last-child{
  margin-bottom: 0;
}

.thumbnail{
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

/* container page */
.container-page{
  width: 100%;
  height: 90vh;
}

.category-home{
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  display: inline-block;
  font-size: 1.4em;
  float: left;
  display: flex;
  height: 32%;
  width: 50%;
  text-transform: uppercase;
  overflow: hidden;
  -webkit-justify-content: center;
  justify-content: center;
}

.home .category-home {
  height: 50%;
}

.home .category-home:first-child{
  background: url('./assets/ELLELA.jpg')
  no-repeat center / cover;
}

.home .category-home:nth-child(2){
  background: url('./assets/1200X800_technologie.jpg')
  no-repeat center / cover;
}

.home .category-home:nth-child(3){
  background: url('./assets/PWA_HOME_OFFRES.jpg')
  no-repeat center / cover;
}

.home .category-home:last-child{
  background: url('./assets/1200x800_references.jpg')
  no-repeat center / cover;
}

.title {
  width: 100%;
  height: 100vh;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 40px;
  line-height: 28px;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 300 !important;
  margin: 0;
}

.title button{
  background: transparent;
  padding: 0.6em 0em;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}

.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.loader .logo{
    width: 146px;
    height: 146px;
    margin: 0 auto 40px auto;
    transform: scale(0.65);
}

.loader .logo .circle{
    display: block;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.loader .logo .circle-1{
    animation: spin ease-in 2s infinite alternate;
}

.loader .logo .circle-2{
    animation: spin ease-in-out 2s infinite reverse;
}

.loader .logo .circle-3{
    animation: spin linear 2s infinite;
}

@keyframes spin {
    0%{
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100%{
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}

.filter{
  overflow: auto;
  white-space: nowrap;
}

.filter ul {
  padding: 0;
  margin: 0;
  text-align: center;
  padding: 0;
  color: #0000ba;
  font-size: 12px;
}

.filter ul li{
  display: inline-block;
  padding: 16px 22px;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 9px;
}

.filter ul li img{
  width: 28px;
  display: block;
  margin: 0 auto 8px auto;
}

/* detail project */
.enter-project{
  position: relative;
  height: 380px;
  color: white;
  text-align: center;
  overflow: hidden
}

.enter-project > *{
  position: relative;
  z-index: 2;
}

.pdf-download {
  position: fixed;
  left: 50%;
  bottom: 25px;
  color: #ffffff;
  cursor: pointer;
  border: 0px;
  display: block;
  width: auto;
  padding: 16px 22px;
  background-color: #3742fa;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  z-index: 2;
  border-radius: 100px;
  transform: translateX(-50%);
}

.title-page {
  font-size: 24px;
  font-weight: lighter;
  margin: 0 0 10px 0;
  line-height: 30px;
  text-transform: uppercase;
}

.customer-name{
  font-size: 18px;
  font-weight: lighter;
  margin: 0;
}

.picto-player{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px !important;
  transform: translate(-50%, -50%);
}

.tag {
  padding: 0 20px 0 0;
  list-style-type: none;
  margin: 0;
  color: #555555;
}

.tag li{
  margin-bottom: 10px;
  border: 1px solid #dddddd;
  padding: 6px 15px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 10px;
  line-height: 14px;
  font-size: 10px;
}

.tag li:last-child{
  margin-bottom: 0;
}

.modal{
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.850);
  z-index: 10;
}

.offline{
  background-color: #a5b1c2;
  color: #ffffff;
  position: fixed;
  width: 100%;
  left: 0;
  text-align: center;
  bottom: -60px;
  z-index: 20;
  padding: 20px;
  transition-duration: 0.5s;
}

.offline.active {
  bottom: 0;
  transition-duration: 0.5s;
}

.offline button {
  top: 14px;
  right: 15px;
  border: 1px solid #ffffff;
  position: absolute;
  background: transparent;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  border-radius: 100px;
}

.offline button img {
  width: 12px;
}

.content-modal-detail{
  background-color: #ffffff;
  width: 80%;
  text-align: center;
  display: inline-block;
}

.description > p {
  margin: 0;
  line-height: 22px;
}

/* detail project */

.detail-project{
  padding: 2em;
  position: relative;
}

.detail-project .content{
  margin-top: -60px;
  padding: 3em 2em;
  display: flex;
  background-color: #ffffff;
}

.detail-project h3 {
  color: #2f3542;
  margin: 0 0 0px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.detail p {
  font-weight: lighter;
}

.detail-project .content-gallery{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(10px, auto);
}

.detail-project .content-gallery > div{
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
}

.detail-project .content-gallery img{
  position: absolute;
  height: 100%;
  width: auto;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.6s;
}

.detail-project .modal-gallery{
  background: rgba(255, 255, 255, 0.960);
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  overflow: hidden;
}

.detail-project .modal-gallery.open{
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0.3s;
}

.detail-project .modal-gallery .tools{
  position: absolute;
  padding: 10px 30px;
  height: 55px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.detail-project .modal-gallery .tools .pagination{
  display: inline-block;
  font-size: 16px;
  height: 35px;
  line-height: 38px;
}

.detail-project .modal-gallery .tools .close-modal{
  display: block;
  height: 35px;
  width: 35px;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  font-size: 20px;
  background-color: white;
  padding: 0;
  float: right;
  vertical-align: top;
}

.detail-project .modal-gallery .swipeable-gallery{
  position: relative;
  width: 100%;
  height: 100%;
}

.detail-project .modal-gallery .btn-prev-gallery, .detail-project .modal-gallery .btn-next-gallery {
  position: fixed;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 80px;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 3;
  cursor: pointer;
}

.detail-project .modal-gallery .btn-prev-gallery img, .detail-project .modal-gallery .btn-next-gallery img {
  width: 22px;
}

.detail-project .modal-gallery .btn-next-gallery img{
  transform: rotate(180deg);
}

.detail-project .modal-gallery .btn-prev-gallery{
  left: 30px;
  top: 50%;
}

.detail-project .modal-gallery .btn-next-gallery{
  right: 30px;
  top: 50%;
}

.detail-project .modal-gallery .content-modal{
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
}

.btn-video-play {
  top: 50%;
  position: absolute;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
  border: 0px;
  display: block;
  width: auto;
  padding: 16px 22px;
  background-color: #3742fa;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  z-index: 2;
  border-radius: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.detail-project .modal-gallery .content-modal .content-modal-gallery{
  height: 100%;
  position: absolute;
  top: 0;
  transition: left 0.3s ease-in;
}

.detail-project .modal-gallery .content-modal .content-modal-gallery .gallery-item{
  width: 100vw;
  float: left;
  position: relative;
  height: 100%;
}

.detail-project .modal-gallery .content-modal .content-modal-gallery .gallery-item img{
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.detail-project .modal-gallery .content-modal .content-modal-gallery .gallery-item .video-component,
.detail-project .modal-gallery .content-modal .content-modal-gallery .gallery-item .iframe-component{
  width: 100%;
  height: 75%;
  top: 50%;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
}

.detail-project .modal-gallery .description {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 2em 15px;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
}

.embed-pdf {
  height: 100vh;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: visible;
}

.detail-project .modal-gallery .description p{
  text-align: center;
  font-size: 16px;
}

/* modal mail */
.modal .content-modal {
  background-color: white;
  padding: 2em;
  max-width: 420px;
}

.modal .content-modal .title-modal {
  font-size: 1.4em;
  text-align: center;
}

.modal .close-modal {
  background-color: white;
  padding: 10px;
  border-radius: 50px;
  margin-bottom: 20px;
  border: 0;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu-burger {
  position: fixed;
  left: 0;
  top: -65px;
  z-index: 10;
  height: 64px;
  width: 100%;
  background: #ffffff;
  text-align: center;
}

input{
  border-bottom: 1px solid #dae2ed;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  padding: 15px 0;
  display: block;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 20px;
  font-size: 14px;
}

.close-modal img{
  width: 12px;
}

.modal .content-modal button {
  width: 100%;
  border: 0px;
  padding: 15px;
  color: #fff;
  cursor: pointer;
  position: relative;
  background: #2f3542;
  text-transform: uppercase;
  font-size: 14px;
}

.title-modal{
  margin: 0 0 30px 0;
  font-size: 18px;
  color: #2f3542;
  line-height: 25px;
}

.modal .content-modal button img{
  fill: #ffffff;
  animation: preloaderAnimate 0.8s linear infinite;
  height: auto;
  width: 20px;
  position: absolute;
  left: 12px;
  top: 13px;
  margin: 0 auto;
}

.message {
  position: fixed;
  bottom: 0;
  font-size: 16px;
  z-index: 10;
  padding: 22px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  left: 0;
  background-color: #a4b0be;
}

#about-section{
  padding: 0 ;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  overflow-x: hidden;
}

#about-section .entry-header{
  margin: 0 auto 100px auto;
  width: 80%;
  max-width: 1200px;
}

#about-section .entry-header .general-title{
  text-align: left;
  font-size: 50px;
  color: #0000ba;
  margin: 120px 0 120px 0;
  line-height: 50px;
}

#about-section .entry-header .intro-text{

}

#about-section .entry-header .intro-text p{
  color: #0000ba;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 15px 0;
}

#about-section .entry-header .intro-text p strong{
  color: #54cbe7;
}

#about-section .entry-expertise{
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

#about-section .entry-expertise .entry-content{
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

#about-section .entry-expertise .entry-content .entry-title{
  font-size: 30px;
  color: #0000ba;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 80px;
  line-height: 1;
}

#about-section .entry-expertise .entry-content .content-expertises{
  margin-bottom: 80px;
}

#about-section .entry-expertise .entry-content .content-expertises .expertise-block{
    position: relative;
    padding-left: 140px;
    float: left;
    margin-bottom: 100px;
    padding-right: 30px;
}

#about-section .entry-expertise .entry-content .content-expertises .expertise-block.col-2{
  width: 100%;
}

#about-section .entry-expertise .entry-content .content-expertises .expertise-block.col-1{
  width: 50%;
}

#about-section .entry-expertise .entry-content .content-expertises .expertise-block .thumbnail{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100px !important;
    max-width: 100px !important;
    height: 100px;
}

#about-section .entry-expertise .entry-content .content-expertises .expertise-block .expertise-title{
  font-size: 20px;
  color: #0000ba;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 10px 0;
}

#about-section .entry-expertise .entry-content .content-expertises .expertise-block .expertise-description{
  font-size: 18px;
  color: #0000ba;
  margin: 0;
  line-height: 1.4;
}

#about-section .entry-expertise{
  position: relative;
}

#about-section .entry-expertise .content-background-image{
    position: absolute;
    top: -220px;
    left: 60%;
    width: 600px;
    height: 600px;
    border-radius: 100%;
    overflow: hidden;
    background-position: 50%;
    background-size: cover;
}

#about-section .entry-methodology{
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 80px auto;
}

#about-section .entry-methodology .entry-content{
  position: relative;
  z-index: 1;
}

#about-section .entry-methodology .entry-content .entry-title{
  font-size: 30px;
  color: #0000ba;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 80px;
  line-height: 1;
}

#about-section .entry-methodology .entry-content .entry-description{
  color: #0000ba;
  font-size: 20px;
  margin: 0 0 60px 0;
}

#about-section .entry-methodology .entry-content .entry-description strong{
  color: #54cbe7;
  font-size: 20px;
  margin: 0 0 15px 0;
}

#about-section .entry-methodology .content-background-image{
    position: absolute;
    left: -150px;
    top: 0;
    border-radius: 100%;
    overflow: hidden;
    width: 600px;
    height: 600px;
    background-position: 50%;
    background-size: cover;
}

#about-section .entry-methodology .content-methodology{
  width: 100%;
  padding: 55px 0 0 500px;
  height: 600px;
}

#about-section .entry-methodology .content-methodology .item-methodology{
  display: inline-block;
  width: 50%;
  height: 195px;
  vertical-align: top;
  padding: 0 20px;
}

#about-section .entry-methodology .content-methodology .item-methodology:first-child{
  margin-bottom: 100px;
}

#about-section .entry-methodology .content-methodology .item-methodology img{
  display: block;
  width: 100px;
  height: auto;
  margin: 0 auto 20px auto;
}

#about-section .entry-methodology .content-methodology .item-methodology .entry-title{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 25px;
  color: #0000ba;
  margin: 0;
}

#about-section .entry-group{
  background-color: #0000ba;
  color: white;
  padding: 80px 0 40px 0;
}

#about-section .entry-group .entry-title{
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 auto 80px auto;
  width: 80%;
  max-width: 1200px;
}

#about-section .entry-group .entry-content{
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 20px auto;
}

#about-section .entry-group .entry-content p{
  font-size: 20px;
  margin: 20px 0;
}

#about-section .entry-group .entry-content p strong{
  color: #54cbe7;
}

#about-section .entry-group .thumbnail{
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 1100px){

  #about-section .entry-header,
  #about-section .entry-expertise,
  #about-section .entry-methodology,
  #about-section .entry-group .entry-title,
  #about-section .entry-group .entry-content{
    width: 100%;
    padding: 0 40px;
  }

}

@media screen and (max-width: 1024px){

  .enter-project{
    height: 600px;
  }
  
}

@media screen and (max-width: 950px){
  #about-section .entry-expertise .content-background-image{
    top: -180px;
  }

  #about-section .entry-expertise .content-background-image img{
    transform-origin: right;
  }

  #about-section .entry-methodology .content-background-image{
    left: auto;
    right: 50%;
  }

  #about-section .entry-methodology .content-methodology{
    width: 50%;
    margin-left: 50%;
    padding: 55px 0 0 0;
  }
}

@media screen and (max-width: 800px){

    #about-section .entry-header{
        margin-bottom: 80px;
    }

  #about-section .entry-header,
  #about-section .entry-expertise,
  #about-section .entry-methodology,
  #about-section .entry-group .entry-title,
  #about-section .entry-group .entry-content{
    width: 100%;
    padding: 0 20px;
  }

  #about-section .entry-header .general-title,
  #about-section .entry-group .entry-title{
    text-align: center;
  }

  #about-section .entry-header .intro-text,
  #about-section .entry-methodology .entry-content .entry-description,
  #about-section .entry-group .entry-content{
    text-align: justify;
  }

  #about-section .entry-header .intro-text p,
  #about-section .entry-methodology .entry-content .entry-description p,
  #about-section .entry-group .entry-content p{
    line-height: 30px;
    display: inline;
  }

    #about-section .entry-expertise .content-background-image{
        display: block;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        height: 0;
        padding-top: 66.666%;
        margin-bottom: 80px;
    }

    #about-section .entry-methodology{
        margin-bottom: 0;
    }

    #about-section .entry-methodology .content-background-image{
        display: block;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
        margin-top: 60px;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        height: 0;
        padding-top: 66.666%;
    }

  #about-section .entry-expertise .entry-content .content-expertises .expertise-block{
    margin-bottom: 60px;
  }

  #about-section .entry-expertise .entry-content .content-expertises .expertise-block.col-1{
    width: 100%;
  }

  #about-section .entry-methodology .content-methodology{
    width: 100%;
    padding: 0;
    margin: 0;
    height: auto;
  }
}

@media screen and (max-width: 610px){

  .btn-next-gallery, .btn-prev-gallery{
    top: initial;
    bottom: 10%;
    transform: translate(0%)
  }

  body{
    padding-top: 52px;
  }
  
  .detail-ref{
    padding-bottom: 48px;
  }

  #app-header {
    padding: 16px 0 6px 0;
  }

  .logo img {
    width: 21px;
  }

  #app-header button{
    top: 18px;
  }

  .arrow-return-header{
    top: 20px;
    left: 18px;
  }

  #nav-header {
    transform: translate(0, -100%);
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .content-modal{
    width: 100%;
  }

  #nav-header ul li {
    padding: 24px 20px;
    display: block;
  }

  #nav-header ul {
    padding: 0;
  }

  .nav-burger {
    right: 16px;
    top: 20px;
  }

  .container-page {
    display: block;
    height: auto;
  }

  .title {
    padding: 60px 20px;
  }

  .filter ul {
    font-size: 10px;
  }

  .filter ul li {
    display: inline-block;
    padding: 16px 22px;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 9px;
  }

  .filter ul li img {
    width: 26px;
  }

  .detail-project{
    padding: 1.8em;
  }

  .detail-project .content {
    flex-direction: column-reverse;
    margin-top: 0;
    padding: 0;
  }

  .tag {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
  }

  .enter-project {
    height: 240px;
  }

  .content-gallery {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6px;
  }

  .title-page {
    font-size: 18px;
    margin: 0 0 12px 0;
    line-height: 1.3;
  }

  .picto-player {
    width: 40px !important;
  }

  .close-modal{
    right: 20px;
    top: 20px;
  }

  .content-modal-detail{
    width: 100%;
    
  }

  .message {
    padding: 20px;
    font-size: 14px;
  }

  .category-home {
    height: 250px;
    width: 100%
  }

}

@media screen and (max-width: 610px){

  #about-section .entry-header .intro-text, 
  #about-section .entry-methodology .entry-content .entry-description, 
  #about-section .entry-group .entry-content {
    text-align: left;
  }

  .home .category-home {
    height: 260px;
  }

}